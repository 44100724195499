.s-workshops-list {
  padding: clamp(50px, 7vw, 110px) 0;
  font-family: var(--workshop-item-ff);

  .section__title {
    font-size: clamp(34px, 6vw, 64px);
    margin-bottom: 30px;
    text-transform: uppercase;
    line-height: 1.1;
    text-align: center;
    @include sm {
      margin-bottom: 20px; } }
  .section__info {
    font-size: 16px;
    text-align: center;
    max-width: 630px;
    margin: 0 auto;
    line-height: 1.5;
    @include sm {
      font-size: 14px; } }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 45px;
    gap: 20px 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include sm {
      margin-top: 30px; }

    &-item {
      flex: 1;
      max-width: 400px;
      min-width: 200px; }

    &-title {
      font-size: 12px;
      font-weight: 500;
      margin-top: 10px; }
    .btn {
      width: 100%;
      height: 48px; } } }

.workshops-list {
  margin-top: 50px;
  display: grid;
  @include sm {
    margin-top: 30px; }
  &__item {
    width: 100%;
    gap: 30px;
    display: grid;
    padding: 32px 0;
    position: relative;
    z-index: 1;
    grid-template-columns: 1fr 240px 210px;
    color: var(--workshop-item-color, #000);
    transition: color .75s cubic-bezier(.19,1,.22,1);
    @include tablet {
      grid-template-columns: 1fr 200px 210px; }
    @include md {
      grid-template-columns: 1fr 260px; }
    @include sm {
      gap: 24px;
      grid-template-columns: 100%;
      padding: 24px 0; }

    &:before,
    &:last-child .workshops-list__item-overlay:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--workshop-item-border, #CFCFCF);
      transition: left .75s cubic-bezier(.19,1,.22,1), right .75s cubic-bezier(.19,1,.32,1);
      @include tablet {
        left: calc(var(--container) * -1);
        right: calc(var(--container) * -1); } }

    &:last-child .workshops-list__item-overlay:before {
      top: auto;
      bottom: -1px; }
    &:after {
      background-color: var(--workshop-item-bg, #ebebeb);
      content: "";
      height: calc(100% + 1px);
      left: calc(var(--container) * -1);
      right: calc(var(--container) * -1);
      position: absolute;
      top: 0;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform .75s cubic-bezier(.19,1,.32,1);

      z-index: -1; }
    &:hover, &:focus-visible {
      &:before,
      & + .workshops-list__item:before,
      &:last-child .workshops-list__item-overlay:before {
        left: calc(var(--container) * -1);
        right: calc(var(--container) * -1); }

      &:after {
        transform: scaleY(1); } }
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      cursor: pointer; }

    &.free {
      .workshops-list__type {
        background: var(--workshop-item-type-bg, #000);
        color: var(--workshop-item-type-color-free, #000); } } }

  &__content {
    display: flex;
    gap: 24px 40px;
    position: relative;
    margin-right: 20px;

    @include md {
      flex-direction: column;
      grid-row: 1/3; }
    @include sm {
      grid-row: auto;
      margin-right: 0; } }

  &__type {
    flex: none;
    align-self: flex-start;
    width: 64px;
    text-align: center;
    line-height: 24px;
    white-space: nowrap;
    background: var(--accentColor);
    color: var(--workshop-item-type-color, #000);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    border-radius: var(--workshop-item-type-radius, 0);
    transition: background .75s cubic-bezier(.19,1,.22,1);
    span {
      text-transform: none;
      font-size: 11px; } }
  &__info {
    flex: 1 1 550px;
    max-width: 550px;

    @include md {
      flex: none; } }
  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: -3px;
    @include md {
      font-size: 28px;
      margin-top: 0; }
    @include sm {
      font-size: 24px; } }

  &__date {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px 40px;
    max-width: 380px;
    line-height: 1.4;
    @include tablet {
      gap: 10px 20px; }
    @include md {
      font-size: 12px;
      margin-top: 15px; }

    p:not(:first-child:last-child) {
      flex: 1;
      min-width: 160px;
      @include sm {
        min-width: 200px; } }
    a {
      color: inherit;
      text-decoration: underline;
      position: relative;
      z-index: 3;
      &:hover {
        text-decoration: none; } } }
  &__speakers {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin-left: auto;
    width: 100%;
    @include md {
      grid-row: 1/2;
      justify-content: flex-start; }
    @include sm {
      grid-row: auto;
      margin-left: 0; } }
  &__speaker {
    flex: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    @include sm {
      width: max-content;
      max-width: 100%; }

    &-photo {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 4px; }

    &-name {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase; } }

  &__buttons {
    @include md {
      grid-row: 2/3; }
    @include sm {
      grid-row: auto; }

    .btn {
      height: 48px;
      min-height: auto;
      position: relative;
      z-index: 2;
      font-size: 14px;
      line-height: 1.2;
      width: 100%;
      text-transform: uppercase;
      padding-left: 15px;
      padding-right: 15px; } } }
